import * as React from "react"
import Seo from "../components/seo"
import "../styles/styles.sass"
import Header from "../components/header"
import Footer from "../components/footer"
import HireBanner from "../views/hire/hireBanner"

const HirePage = () => {
  return (
  <main className="mercuryView hireView">
    <Seo
      title="Phil Amour — Hire"
      description="Let's get to know each other! I'd love to learn more about you and your business."
      image="/imageSEOHire.jpg"
    />
    
    <Header headerType="detail" />

    <div className="mercuryContent">
      <HireBanner
        bannerTitle="Let's get to know each other..."
        bannerSubtitle="I strongly believe that transparency, good communication, and a great user experience is vital in making a successful product. I'd love to learn more about you and your business! What's the vision behind your product, who are your customers (current and potential), what's important to them, and finally - what problem are we going to be solving?"
      />
    </div>
    
    <Footer />
  </main>
  )
}

export default HirePage
